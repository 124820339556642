import React, { FC, useState } from 'react';
import StartButton from './StartNowButton';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile, tablet } from 'styles/breakpoints';
import bookGif from 'assets/images/results/result_book.gif';
import Check from 'assets/icons/results/pink_checkmark.svg';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';

interface BookReviewResultsProps {
  title: string;
  image: string;
  listItem: string;
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}

const data = {
  cherish_babys_first_years_gift:
    'Beautiful keepsake for parents to always remember their baby’s first years',
  monitor_childs_milestones: 'Created to celebrate milestones and growth',
  give_to_their_child_when_grow_up:
    'Timeless book to pass down when the child grows up',
  share_precious_memories_gift:
    'Unique way to share precious moments with family and friends',
  remember_pregnancy_memories: 'Save the most important pregnancy moments',
  remember_babys_first_years: 'Always remember your baby’s first years',
  track_babys_milestones: 'Track your baby’s milestones',
  track_childs_milestones: 'Track your baby’s milestones',
  give_to_child_when_grow_up: 'Keep it for your child when they grow up',
  share_precious_memories: 'Share precious moments with your family & friends',
};

const BookReviewResults: FC<BookReviewResultsProps> = ({
  title,
  loading,
  image,
  buttonText,
  listItem,
  onClick,
}) => {
  const babyName = useSelector(
    (state: AppState) => state.user.quiz_answers?.baby_name,
  );
  const bookPurposeGift = useSelector(
    (state: AppState) => state.user.quiz_answers?.whats_meaning_behind_gift,
  );
  const bookPurposeMyself = useSelector(
    (state: AppState) =>
      state.user.quiz_answers?.excites_most_about_getting_book,
  );
  const filteredListItems = (bookPurposeGift ?? bookPurposeMyself)
    .split('|')
    .map(key => data[key]);

  const renderBabyName = text => {
    const regex = /\${baby_name}/;
    const name = babyName;
    const replacedString = text.replace(regex, name);
    return replacedString;
  };
  return (
    <Container>
      <Title
        color="dark80"
        dangerouslySetInnerHTML={{ __html: renderBabyName(title) }}
      />
      <ImageWrapper />
      <ListContainer>
        <ListItemContainer>
          <SvgContainer>
            <Check />
          </SvgContainer>
          <ListItem>{listItem}</ListItem>
        </ListItemContainer>
        {filteredListItems.map((item, index) => (
          <ListItemContainer key={index}>
            <SvgContainer>
              <Check />
            </SvgContainer>
            <ListItem>{item}</ListItem>
          </ListItemContainer>
        ))}
      </ListContainer>
      <Button onClick={onClick} loading={loading}>
        {buttonText}
      </Button>
    </Container>
  );
};

export default BookReviewResults;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 28.75rem;
  width: 100%;
  background-image: url(${bookGif});
  background-attachment: fixed;
  height: 19.1108rem;
  background-size: 40rem;
  background-position: 50% -11rem;
  background-attachment: scroll;
  @media ${mobile} {
    background-size: 31rem;
    background-position: 50% -8rem;
    height: 15rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  span {
    color: #ef71a0;
  }
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    line-height: 2rem;
    max-width: 343px;
    width: 100%;
  }
  @media ${mobile} {
    margin-bottom: 1.25rem;
  }
`;

const ListContainer = styled.div`
  display: flex;
  max-width: 23.1rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.25rem;
  @media ${mobile} {
    margin-top: 1rem;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ListItem = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SvgContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
`;

const Button = styled(StartButton)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 100px;
  width: 100%;
  height: 3.5rem;
  @media ${tablet} {
    height: 3rem;
  }
`;
