import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, tablet } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface BookInsightsProps {
  title: string;
  subtitle: string;
  cards: {
    title: string;
    subTitle: string;
    number: string;
  }[];
  onClick: () => void;
  loading?: boolean;
  listTitle: string;
  list: string[];
}

const BookInsights: FC<BookInsightsProps> = ({
  title,
  subtitle,
  cards,
  listTitle,
  list,
  onClick,
  loading,
}) => {
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  return (
    <Container>
      <InnerContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <CardContainer>
          {cards.map((item, index) => (
            <Card key={index}>
              <Number>{item.number}</Number>
              <CardInner>
                <CardTitle>{item.title}</CardTitle>
                <TextStyled textAlign="center">{item.subTitle}</TextStyled>
              </CardInner>
            </Card>
          ))}
        </CardContainer>
        <ListTitle>{listTitle}</ListTitle>
        <List>
          {list.map((item, index) => (
            <ListItem key={index}>
              <ListNumber>{index + 1}</ListNumber>
              {item}
            </ListItem>
          ))}
        </List>
        <Button onClick={onClick} loading={loading}>
          Get my book
        </Button>
      </InnerContainer>
    </Container>
  );
};

export default BookInsights;

const Button = styled(StartNowButton)`
  width: 100%;
  height: 3.5rem;
  margin-top: 1.5rem;
  @media ${tablet} {
    height: 3rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  @media ${tablet} {
  }
`;

const Card = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: #fff;
  min-height: 5.5rem;
  background: #fff;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.15);
`;

const CardTitle = styled.p`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const TextStyled = styled.p`
  color: #4d4d4d;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  @media ${tablet} {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    line-height: 2rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 1.5rem 0 1rem;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const ListTitle = styled(Text)`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 2rem 0 1rem;
  width: 100%;
  @media ${tablet} {
    font-size: 1.125rem;
    padding: 1.5rem 0 1rem;
  }
`;

const Number = styled(Text)`
  display: flex;
  width: 6.25rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  opacity: 0.81;
  background: #ef71a0;
  color: #fff;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.75rem;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
`;

const ListItem = styled.div`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const ListNumber = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #ef71a0;
  border-radius: 1.5rem;
  color: #ef71a0;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  padding: 1rem;
  height: 88px;
  width: 100%;
  border-radius: 0 0.75rem 0.75rem 0;
  border-top: 1px solid #ffd9e7;
  border-right: 1px solid #ffd9e7;
  border-bottom: 1px solid #ffd9e7;
`;

const Container = styled.div`
  padding: 3rem 1rem;
  background: #f0f0f0;

  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 28.75rem;
`;
