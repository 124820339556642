import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import Signature from 'assets/icons/results/promise-signature.svg';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

interface OurPromiseProps {
  title?: string;
  text?: string;
  bottomText?: string;
  img: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 28.75rem;
  width: 100%;
  margin: 0 auto;
  @media ${tablet} {
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  z-index: -1;
  border-radius: 1rem;
  @media ${tablet} {
    height: 100%;
  }
`;

const StyledBottomText = styled(Text)`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.018rem;
  span {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 0.25rem;
  }
  @media ${tablet} {
    font-size: 1rem;
    span {
      font-size: 0.875rem;
    }
  }
`;

const StyledText = styled(Text)`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-bottom: 1.25rem;
`;

const SvgContainer = styled.div`
  width: 2.65231rem;
  height: 2.93025rem;
`;

const OurPromise: FC<OurPromiseProps> = ({ title, text, bottomText, img }) => {
  const { isTablet, isMobile } = useQuery();

  return (
    <Container>
      <Title>{title}</Title>
      <DynamicImage src={img} alt="Welcome Baby creator" />
      <Card>
        {text && (
          <StyledText
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
        <TextContainer>
          {bottomText && (
            <StyledBottomText
              dangerouslySetInnerHTML={{
                __html: bottomText,
              }}
            />
          )}
          <SvgContainer>
            <Signature />
          </SvgContainer>
        </TextContainer>
      </Card>
    </Container>
  );
};

export default OurPromise;
